import { ThemeProvider as NextThemeProvider, useTheme } from 'next-themes'
import { AppProps } from 'next/app'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { FetchParamsProvider } from 'sdk'
import { ThemeProvider } from 'styled-components'
import {
  ConnectionError,
  DarkTheme,
  GlobalStyle,
  LightTheme,
  TopLevelGrid,
  TopNav,
} from 'ui'

function AppWithContext({ Component, pageProps }: AppProps) {
  return (
    <TopLevelGrid>
      <TopNav />
      <Component {...pageProps} />
      <ConnectionError />
    </TopLevelGrid>
  )
}

function AppWithStyles(props: AppProps) {
  const { resolvedTheme } = useTheme()
  const queryClient = new QueryClient()

  return (
    <ThemeProvider theme={resolvedTheme === 'light' ? LightTheme : DarkTheme}>
      <GlobalStyle />
      <FetchParamsProvider>
        <QueryClientProvider client={queryClient}>
          <AppWithContext {...props} />
        </QueryClientProvider>
      </FetchParamsProvider>
    </ThemeProvider>
  )
}

export default function App(props: AppProps): JSX.Element {
  return (
    <>
      <NextThemeProvider>
        <AppWithStyles {...props} />
      </NextThemeProvider>
    </>
  )
}
